
export const incomechecklists = {
        id:"income",
        title:"Income Sources",
        sources:[
          {
            id:'incomeSource01',
            name:"Employment",
            slips:[
              {
              name:"T4 Statement of Remuneration Paid",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/tax-slips/understand-your-tax-slips/t4-slips/t4-statement-remuneration-paid.html"
            },
            {
              name:"Other Income",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/personal-income/line-13000-other-income.html"
            },
          ]
          },
          {
            id:'incomeSource02',
            name:"Self-employment or (skip,uber)",
            slips:[
              {
              name:"T2125 Statement of Business or Professional Activities",
              link:"https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t2125.html"
            },
            {
              name:"T5013 Statement of Partnership Income",
              link:"https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t5013.html"
            },
            {
              name:"Rental income ",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/personal-income/rental-income-line-12599-gross-line-12600-net.html"
            },
            {
              name:"Uber or Skip income",
            }
          ]
          },
    
          {
            id:'incomeSource03',
            name:"Savings and Investments",
            slips:[
              {
              name:"T5 Statement of Investment Income",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/tax-slips/understand-your-tax-slips/t5-slips/t5-statement-investment-income-slip-information-individuals.html"
            },
            {
              name:"T3 Statement of Trust Income Allocations and Designations",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/tax-slips/understand-your-tax-slips/t3-statement-trust-income-allocations-designations-slip-information-individuals.html"
            },
            {
              name:"T5008 Statement of Securities Transactions",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/tax-slips/understand-your-tax-slips/t5-slips/t5008-statement-securities-transactions-slip-information-individuals.html"
            },
            {
              name:"Capital Gains including the sale of your principal residence.",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/personal-income/line-12700-capital-gains/principal-residence-other-real-estate/sale-your-principal-residence.html"
            },
            {
              name:"Amounts for carrying charges and interest expenses related to your investments. This includes fees for managing your investments.",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-22100-carrying-charges-interest-expenses.html"
            },
            {
              name:"Home Buyer's Plan repayment amounts.",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/rrsps-related-plans/what-home-buyers-plan/repay-funds-withdrawn-rrsp-s-under-home-buyers-plan.html"
            },
            {
              name:"Lifelong Learning Plan repayment amounts.",
              link:"https://www.canada.ca/en/revenue-agency/services/forms-publications/publications/rc4112/lifelong-learning-plan.html#P362_31252"
            }
          ]
          },
    
          {
            id:'incomeSource04',
            name:"Retirement",
            slips:[
              {
              name:"T4A Statement of Pension, Retirement, Annuity, and Other Income",
              link:"https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t4a.html"
            },
            {
              name:"T4A(P) Statement of Canada Pension Plan Benefit",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/tax-slips/understand-your-tax-slips/t4-slips/t4a-p-statement-canada-pension-plan-benefits.html"
            },
            {
              name:"T4A(OAS) Statement of Old Age Security",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/tax-slips/understand-your-tax-slips/t4-slips/t4a-oas-statement-old-security.html"
            },
            {
              name:"T4RSP Statement of RRSP Income",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/completing-slips-summaries/t4rsp-t4rif-information-returns/t4rsp-slip-summary/t4rsp-statement-rrsp-income.html"
            },
            {
              name:"T4RIF Statement of income from a Registered Retirement Income Fund",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/tax-slips/understand-your-tax-slips/t4-slips/t4rif-statement-income-a-registered-retirement-income-fund-slip-information-individuals.html"
            }
          ]
          },
    
          {
            id:'incomeSource05',
            name:"Social Benefits and EI Benefits",
            slips:[
              {
              name:"T4E Statement of Employment Insurance and Other Benefits",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/tax-slips/understand-your-tax-slips/t4-slips/t4e-statement-employment-insurance-other-benefits.html"
            },
            {
              name:"T5007 Statement of Benefits (Workers Compensation or Social Assistance benefits)",
              link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/tax-slips/statement-benefits-t5007.html"
            },
            {
              name:"T4A COVID-19 Benefits received  (CERB, CRB, CRCB, etc.)",
            }
          ]
          }
        ]
}

export const  expensechecklists = {
  id:"expense",
  title:"Expense Receipts for Credits and Deductions",
  sources:[
  {
    id:'expenseSource01',
  name:"Work-related Expenses",
  slips:[
      {
      name:"T2200 Employment Expenses (signed by your employer)",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-22900-other-employment-expenses.html"
  },
  {
      name:"In-home office expenses",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-22900-other-employment-expenses/work-space-home-expenses.html"
  },
  {
      name:"Tool expenses",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-22900-other-employment-expenses/employed-tradespersons-including-apprentice-mechanics/deduction-tools-tradespersons.html"
  },
  {
      name:"Union dues not included on your T4 slip",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-21200-annual-union-professional-like-dues.html"
  },
  ]
  },
  {
    id:'expenseSource02',
  name:"For Families",
  slips:[
      {
      name:"Childcare expenses",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-21400-child-care-expenses.html"
  },
  {
      name:"Adoption expenses",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-31300-adoption-expenses.html"
  },
  {
      name:"Support payments made",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/lines-21999-22000-support-payments-made.html"
  },
  ]
  },

  {
    id:'expenseSource03',
  name:"For Homeowners and Landlords",
  slips:[
      {
      name:"Capital Cost Allowance (CCA)",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/sole-proprietorships-partnerships/report-business-income-expenses/claiming-capital-cost-allowance.html"
  },
  {
      name:"Home accessibility tax credit (HATC)",
      link:"https://www.canada.ca/en/revenue-agency/programs/about-canada-revenue-agency-cra/federal-government-budgets/budget-2015-strong-leadership/home-accessibility-tax-credit-hatc.html"
  },
  {
      name:" Moving expenses",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-21900-moving-expenses.html"
  }
  ]
  },

  {
    id:'expenseSource04',
  name:"For Students",
  slips:[
      {
      name:"Tuition slips",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-32300-your-tuition-education-textbook-amounts.html"
  },
  {
      name:"Student loan interest amounts",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-31900-interest-paid-on-your-student-loans.html"
  },
  ]
  },
  
  {
    id:'expenseSource05',
  name:"Other Common Expenses",
  slips:[
      {
      name:"Charitable donations",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/life-events/what-when-someone-died/final-return/complete-final-return-steps/common-types-income-a-final-return/federal-non-refundable-tax-credits/line-34900-donations-gifts.html"
  },
  {
      name:"Medical expenses",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/lines-33099-33199-eligible-medical-expenses-you-claim-on-your-tax-return.html"
  },
  {
      name:"Personal attendant/facility care expenses",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/lines-33099-33199-eligible-medical-expenses-you-claim-on-your-tax-return/attendant-care-care-a-facility.html"
  },
  {
      name:"Political contributions",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/federal-political-contributions-line-40900-total-contributions-line-41000-tax-credit.html"
  },
  {
      name:"Exams for professional certification",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/line-32300-your-tuition-education-textbook-amounts/eligible-tuition-fees.html"
  },
  {
      name:"T622 Digital News Subscription Tax Credit - Eligible Subscription",
      link:"https://www.canada.ca/en/revenue-agency/services/forms-publications/forms/t622.html"
  },
  {
      name:"Eligible educator school supply tax credit",
      link:"https://www.canada.ca/en/revenue-agency/services/tax/individuals/topics/about-your-tax-return/tax-return/completing-a-tax-return/deductions-credits-expenses/lines-46800-46900-eligible-educator-school-supply-tax-credit.html"
  }
  ]
  },

  {
    id:'expenseSource06',
    name:"Province of residence related credits",
    slips:[
        {
        name:"Rent receipts",
        link:""
    },
    {
      name:"Property tax",
      link:""
    },
    {
      name:"Children’s fitness and arts expenses.",
      link:""

    }
    ]
    },
        
]
}
