import React, {useState} from "react";
// import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { chevronDown } from 'react-icons-kit/ionicons/chevronDown';
import Icon from 'react-icons-kit';

import tw, { styled } from 'twin.macro'
import DocumentsList from "./DocumentsList";

const CardLeft = styled.div(({ hasExpense }) => [
  tw`h-48 lg:h-auto lg:w-48 flex-none bg-green-800 rounded-t lg:rounded-t-none lg:rounded-l text-center`,
  hasExpense && tw`bg-red-800`,
])

const ListItem = styled.div(({ hasExpense }) => [
  tw`text-green-900 font-bold text-lg mb-2`,
  hasExpense && tw`text-red-900`,
])
const Source = tw.dt`flex justify-between items-center`;
const SourceText = tw.span`text-lg lg:text-xl `;
const SourceToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);



const TaxChecklist = (props) =>{
    const {checklists, hasExpense} = props

    const [activeSourceIndex, setActiveSourceIndex] = useState(
      new Array(checklists.sources.length).fill(false)
    );
    
    const updatedCheckedState = (position) => activeSourceIndex.map((item, index)=> index === position ? !item : item)
      
    const toggleSource = (sourceIndex) => {
     setActiveSourceIndex(updatedCheckedState(sourceIndex))
    };
  
    return(
        <>
  <div tw="max-w-sm w-full lg:max-w-full lg:flex mt-10">
  
  <CardLeft hasExpense={hasExpense}>
  <div tw='text-white font-bold text-xl mt-10'>{checklists.title}</div>
  </CardLeft>
  
  <div tw="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
    <div tw="mb-8">
      <ul tw='bg-white lg:w-96 text-gray-900'>
    {checklists.sources.map(({id, name, slips }, index) => {
          return (
            <ListItem key={id} hasExpense={hasExpense}>
             
                <Source tw="cursor-pointer select-none" onClick={() => {
                  toggleSource(index);
                }}>
                  <SourceText>
                  
               {name}
               
                    </SourceText>
                    
                  <SourceToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 },
                    }}
                    initial="collapsed"
                    animate={activeSourceIndex[index] ? 'open' : 'collapsed'}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <Icon icon={chevronDown} />
                  </SourceToggleIcon>
                </Source>
                
                
                 {(activeSourceIndex[index]) ?
                  <DocumentsList slips = {slips}/>  :
                  <>{null}</>
              }
                            </ListItem>
            
          );
        })}
      </ul>
    </div>
    
  </div>

   
  </div>

 </>
    )
}

export default TaxChecklist

TaxChecklist.propTypes = {
    // name:PropTypes.string.isRequired
    
    // successMessage: PropTypes.string.isRequired,
    // failureMessage: PropTypes.string.isRequired,
  };
  