import React,{useContext} from "react";
import tw, { styled } from 'twin.macro'

import {
    GlobalStateContext,
    GlobalDispatchContext
  } from "../../contexts/GlobalContextProvider"
import {removeDocument} from '../../contexts/actions'

const Container = tw.div` w-full border border-gray-400 lg:border-gray-400 bg-white 
rounded-b lg:rounded  p-4 flex flex-col justify-between leading-normal`;

const List = tw.dt`flex justify-between items-center`;
const ListText = tw.span`text-gray-700 text-base `;
const ListIcon = styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const DisplayCheckedDocuments = () => {
    const {taxCheckedDocuments} = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)  
    return(
        <>
         <Container >
  
        <div tw="flex items-center">
        <h1 tw="text-lg font-semibold text-gray-800 dark:text-white">Documents checklist.</h1>
        </div>
        <div tw="mt-6">
            <div>
                <p tw="text-sm">
                The CRA offers a variety of credits and deductions to those eligible, which help you reduce your tax owed. Throughout the year,Generally, you must keep all required records and supporting documents for a period of six years from the end of the last tax year.
                </p>
            </div>
           {
           (taxCheckedDocuments.length === 0) ? 
           <>
           <div tw="mt-6">
           You did not selected any document.
           </div>
            </> :
           <>
           <span tw="mt-6 mb-6 text-lg font-semibold text-gray-800 dark:text-white">
                Documents required.               
           </span>
           
           <span tw="ml-6">
           Total:
           <span tw="inline-flex items-center justify-center px-2 ">{taxCheckedDocuments.length}</span>
           
           </span>
           
            {React.Children.toArray(
                  taxCheckedDocuments.map((document) => (
                    <div tw='ml-4'>
            <List>
                  <ListText>
                 <span >*</span> {document}
                  </ListText>

                <ListIcon>
                 <button onClick={() => dispatch(removeDocument(document))}>
                 <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                 </button>
                 </ListIcon>
           

          </List>
          </div>
                    // <ul tw="list-disc">     
                    // <li >
                    //   <span >{document}</span>
                    // </li>
                    // </ul>
                   
                  )),
                )}
           </>
           }
            
        </div>

       
    </Container>
   </>
    )
}
export default DisplayCheckedDocuments
