import React,{useContext} from "react";
import tw, { styled } from 'twin.macro'

import {
  GlobalDispatchContext
} from "../../contexts/GlobalContextProvider"
import {addDocument} from '../../contexts/actions'

const List = tw.dt`flex justify-between items-center`;
const ListText = tw.span`text-gray-700 text-base `;
const ListIcon = styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`;

const DocumentsList = (props) => {
  const dispatch = useContext(GlobalDispatchContext)
  
    const {slips} = props
    // const [checkedState, setCheckedState] = useState(
    //   new Array(slips.length).fill(false)
    // );
   
    // const updatedCheckedState = (position) => checkedState.map((item, index)=> index === position ? !item : item)
     
    
    // const handleOnChange = (position, slip) => {
    //   if(!checkedState[position]){
    //     dispatch(addDocument(slip))
        
    //   } else{
    //     dispatch(removeDocument(slip))
    //   }
      
    //   setCheckedState(updatedCheckedState(position))
     
    // }
    return(
      (slips) ?
    
      <>
      {slips.map(({name, id })=>{
        return(
          <div tw='ml-4'key={id}>
            <List>
                  <ListText>
                 <span >*</span> {name}
                  </ListText>

                <ListIcon>
                 <button onClick={() => dispatch(addDocument(name))}>
                 <svg xmlns="http://www.w3.org/2000/svg" tw="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                 </button>
                 </ListIcon>
           

          </List>
          </div>
          
                    
        )
      })
        
      }
      
      </> 
     :
     <>nothing to display</>
   
    )
    
}
export default DocumentsList