import React from 'react';
import tw from 'twin.macro';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import {
  SectionIntroduction,
  SectionHeading,
} from '../components/misc/Typography';

import {incomechecklists, expensechecklists} from '../common/data/tax-checklists'
import TaxChecklist from '../components/tax-checklist/TaxChecklist';
import DisplayCheckedDocuments from '../components/tax-checklist/DisplayCheckedDocument';

const Container = tw.div`grid grid-flow-row-dense grid-cols-1 lg:grid-cols-3`;

const CanadaTaxChecklist = ({
  intro = 'Canada Tax Documents Checklist',
  heading = (
    <>
      What Documents Do I Need to File My Taxes? 
    </>
  ),
  descriptionContact = 'Stay organized and get a head start on this tax season with this list of forms and receipts you’ll need to file your taxes.',
}) => {
  

  return (
    <Layout>
      <Seo title="Tax-Checklist " description="Canada Tax Documents Checklist: What Documents Do I Need to File My Taxes? " />
      <div tw="container mx-auto">
      <div tw='mt-16'>
          <SectionIntroduction>{intro}</SectionIntroduction>
            <SectionHeading>{heading}</SectionHeading>
            
            
            <div tw='text-justify'>
            {descriptionContact}
                </div> 
                
          </div>
         
          <Container>
  <div tw='mb-10 lg:col-span-2'>
    <TaxChecklist checklists={incomechecklists} />
    <TaxChecklist checklists={expensechecklists} hasExpense={true}/>
    </div>
    <div tw='mb-10 mt-10'>
        <DisplayCheckedDocuments/>
    </div>
   
</Container>

</div>
      </Layout>
  );
};

export default CanadaTaxChecklist;